import { createContext, useState } from 'react'
import { SearchBySku } from './components/SearchBySku'
import './styles.scss'
import { UploadFile } from './components/UploadFile'


// Crear el contexto
export const successContext = createContext()

function App() {
  
  const [success, setSuccess] = useState(null) // Usar null o false

  return (
    
    <successContext.Provider value={{ success, setSuccess }}> {/* Proveer setSuccess */}
        
        { success === true ? <SearchBySku /> : <UploadFile /> }
    </successContext.Provider>
  )
}

export default App
